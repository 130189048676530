// src/components/hero.js
import React from "react"

export default function Hero({ message1, message2, textSize, bgClass = "" }) {
  const size = textSize != null ? textSize : "text-2xl"

  return (
    <div className="hero">
      <div className="w-full mx-auto m-0 bg-gradient-to-br from-gray-200 to-gray-50">
        <div
          className={
            `h-48 lg:h-60 flex items-center p-3 max-w-3xl mx-auto` + bgClass
          }
        >
          <div className={` animate-fade-in-left ` + size}>
            <h1 className="text-gray-700 py-3 tracking-wide leading-tight">
              {message1}
              <span className="inline-block">{message2}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Link } from "gatsby"
import React from "react"

export default function Button({ link, label, external }) {
  return (
    <div className="flex justify-center pb-6">
      {external ? (
        <a href={link} target="_blank" rel="noreferrer noopener">
          {GetButton(label)}
        </a>
      ) : (
        <Link to={link}>{GetButton(label)}</Link>
      )}
    </div>
  )
}

function GetButton(label) {
  return (
    // <button className="shadow-md shadow-sky-900/50 transition duration-300 ease-in bg-sky-800 border-0 mt-4 py-2 px-8 focus:outline-none hover:bg-sky-900 hover:scale-105 rounded-full w-48">
    <button className="shadow-md bg-white transition duration-300 ease-in border-sky-600 text-sky-600 border-2 mt-4 py-2 px-8 focus:outline-none hover:border-sky-700 hover:text-sky-700 hover:scale-105 rounded-full w-48">
      {label}
    </button>
  )
}

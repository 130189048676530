// src/components/hero.js
import React from "react"
import Button from "./button"

export default function Contact() {
  return (
    <div className="w-full mx-auto m-0">
      <div
        className="
        text-white
        h-72 
        bg-gradient-to-r from-slate-500 to-slate-700 

      flex justify-center items-center p-3 text-center"
      >
        <div className="">
          <div>ご相談はこちらからお問い合わせください</div>
          <Button
            link={"/contact"}
            label={"お問い合わせ"}
            external={false}
          ></Button>
        </div>
      </div>
    </div>
  )
}
